/*
/ created by Han
/ 
/ Show/Hide Menu when click the button "MENU"
/
/ useState, Boolean
/
*/
/*eslint-disable*/

import React, { useState } from "react";
import { InfoSection } from "../../components";
import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from "./Data";

import { BtnStyle, MenuContainer } from "./Home.elements";

import { Button } from "../../globalStyles";
import menu1 from "../../images/Slide1.jpg";
import menu2 from "../../images/Slide2.jpg";
import menu3 from "../../images/Slide3.jpg";
import menu4 from "../../images/Slide4.jpg";
import menu5 from "../../images/Slide5.jpg";

const Home = () => {
  const [mode, setMode] = useState(true);
  const [showMenu, setShowMenu] = useState();

  function menuDisplay() {
    console.log("menuDisplay clicked", mode)
    setMode(!mode);
    return setShowMenu(
      mode ? (
        <MenuContainer>
            <img src={menu1} />
            <img src={menu2} />
            <img src={menu3} />
            <img src={menu4} />
            <img src={menu5} />
        </MenuContainer>
      ) : null
    );
  }
  return (
    <>
      <InfoSection {...homeObjOne} />
      <BtnStyle>
        <Button fontBig primary onClick={menuDisplay}>
          MENU
        </Button>
      </BtnStyle>
      <div>{showMenu}</div>
      <InfoSection {...homeObjTwo} />
      <BtnStyle>
        <Button fontBig primary onClick={menuDisplay}>
          MENU
        </Button>
      </BtnStyle>
      <div>{showMenu}</div>
      <InfoSection {...homeObjThree} />
      <BtnStyle>
        <Button fontBig primary onClick={menuDisplay}>
          MENU
        </Button>
      </BtnStyle>
      <div>{showMenu}</div>
      <InfoSection {...homeObjFour} />
      <BtnStyle>
        <Button fontBig primary onClick={menuDisplay}>
          MENU
        </Button>
      </BtnStyle>
      <div>{showMenu}</div>
    </>
  );
};

export default Home;
