import React, { useState } from "react";

import { InfoSection } from "../../components";
import { homeObjThree } from "../HomePage/Data";


const Product = () => {
  const [showMenu, setShowMenu] = useState();
  return (
    <>
      <InfoSection {...homeObjThree} />
    </>
  );
};

export default Product;
