import React from 'react'
import { InfoSection } from '../../components'
import { homeObjTwo} from '../HomePage/Data'

const Servcie = () => {
  return (
    <>    
     <InfoSection {...homeObjTwo} />   
     
    </>
  )
}

export default Servcie